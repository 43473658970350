/* eslint-disable unicorn/consistent-destructuring */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { Loader } from "..";
import { useAuthContext } from "../../context/auth";
import { getEdificiosOperativos, saveCotizacion } from "../../utils";
import PdfCotizacion from "../PdfCotizacion";
import { PDFDownloadLink } from "@react-pdf/renderer"; // Importamos PDFDownloadLink

import "./styles.scss";

export const CotizadorProgrammaticCalculadora = ({
  inventarioData,
  plazo,
  frecuencia,
  horasSeleccionadas,
  duracion,
  isProgrammaticSelected,
}) => {
  const [, setEdificios] = useState([]);
  const [ascensoresTotal, setAscensoresTotal] = useState([]);
  const [totemsTotal, setTotemsTotal] = useState([]);
  const [coworkTotal, setCoworkTotal] = useState([]);
  const [edificiosSeleccionadosAscensores, setEdificiosSeleccionadosAscensores] = useState(0); // Inicializa como un número
  const [edificiosSeleccionadosTotems, setEdificiosSeleccionadosTotems] = useState(0); // Inicializa como un número
  const [edificiosSeleccionadosCowork, setEdificiosSeleccionadosCowork] = useState(0); // Inicializa como un número
  const [edificiosUnicosTotal, setEdificiosUnicosTotal] = useState(0); // Inicializa como un número
  const [ascensoresSeleccionados, setAscensoresSeleccionados] = useState(0);
  const [totemsSeleccionados, setTotemsSeleccionados] = useState(0);
  const [coworkSeleccionados, setCoworkSeleccionados] = useState(0);
  const [audienciaSeleccionadaAscensor, setAudienciaSeleccionadaAscensor] = useState(0);
  const [audienciaSeleccionadaTotems, setAudienciaSeleccionadaTotems] = useState(0);
  const [audienciaSeleccionadaCowork, setAudienciaSeleccionadaCowork] = useState(0);
  const [audienciaSeleccionadaTotal, setAudienciaSeleccionadaTotal] = useState(0);
  const [nombresEdificiosSeleccionadosAscensores, setNombresEdificiosSeleccionadosAscensores] = useState([]);
  const [nombresEdificiosSeleccionadosTotems, setNombresEdificiosSeleccionadosTotems] = useState([]);
  const [nombresEdificiosSeleccionadosCowork, setNombresEdificiosSeleccionadosCowork] = useState([]);
  const [nombresEdificiosNoSeleccionados, setNombresEdificiosNoSeleccionados] = useState([]);

  const { session, nombre, apellido, pais } = useAuthContext();

  const localString = (pais) =>
    ({
      Chile: "es-CL",
      Perú: "es-PE",
      Uruguay: "es-UY",
    })[pais] || "es-UY";

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$",
        Perú: "S/",
        Uruguay: "$",
      })[pais] || "$",
    []
  );

  let selectedMoneda;

  const tiposDeCambio = {
    Uruguay: 40,
    Perú: 3.75,
    Chile: 950,
  };

  const operacionTextual = useCallback(
    (pais) =>
      ({
        Chile: "WECAST",
        Perú: "VISIONA",
        Uruguay: "SCREENMEDIA",
      })[pais] || "VISIONA",
    []
  );

  function obtenerTipoDeCambio(pais) {
    return tiposDeCambio[pais];
  }

  const obtenerFechaActual = () => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    const month = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const day = fecha.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const generarCodigoAleatorio = () => {
    const letras = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let codigo = "";
    for (let i = 0; i < 6; i++) {
      codigo += letras.charAt(Math.floor(Math.random() * letras.length));
    }
    return codigo;
  };

  const frecuenciaNumero = parseInt(frecuencia, 10);

  useEffect(() => {
    if (inventarioData) {
      setAscensoresSeleccionados(inventarioData.ascensoresSeleccionados || 0);
      setAudienciaSeleccionadaAscensor(inventarioData.audienciaSeleccionadaAscensor || 0);
      setAudienciaSeleccionadaCowork(inventarioData.audienciaSeleccionadaCowork || 0);
      setAudienciaSeleccionadaTotal(inventarioData.audienciaSeleccionadaTotal || 0);
      setAudienciaSeleccionadaTotems(inventarioData.audienciaSeleccionadaTotems || 0);
      setCoworkSeleccionados(inventarioData.coworkSeleccionados || 0);
      setEdificiosSeleccionadosAscensores(inventarioData.edificiosSeleccionadosAscensores?.length);
      setEdificiosSeleccionadosCowork(inventarioData.edificiosSeleccionadosCowork?.length);
      setEdificiosSeleccionadosTotems(inventarioData.edificiosSeleccionadosTotems?.length);
      setTotemsSeleccionados(inventarioData.totemsSeleccionados || 0);

      // Verifica si los valores son arrays antes de usarlos
      const totalEdificiosUnicos = new Set([
        ...(Array.isArray(inventarioData.edificiosSeleccionadosAscensores)
          ? inventarioData.edificiosSeleccionadosAscensores
          : []),
        ...(Array.isArray(inventarioData.edificiosSeleccionadosTotems)
          ? inventarioData.edificiosSeleccionadosTotems
          : []),
        ...(Array.isArray(inventarioData.edificiosSeleccionadosCowork)
          ? inventarioData.edificiosSeleccionadosCowork
          : []),
      ]).size;

      // Utiliza la función correcta
      setEdificiosUnicosTotal(totalEdificiosUnicos);
    }
  }, [inventarioData]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const newEdificios = await getEdificiosOperativos({ pais });
        newEdificios.sort((a, b) => a.edificio.localeCompare(b.edificio));
        setEdificios(newEdificios);

        const totalAscensores = newEdificios.reduce((sum, edificio) => sum + (edificio.pantallas || 0), 0);
        const totalTotems = newEdificios.reduce((sum, edificio) => sum + (edificio.totems || 0), 0);
        const totalCowork = newEdificios.reduce((sum, edificio) => sum + (edificio.cowork || 0), 0);

        setAscensoresTotal(totalAscensores);
        setTotemsTotal(totalTotems);
        setCoworkTotal(totalCowork);

        const nombresEdificiosSeleccionadosAscensores = newEdificios
          .filter(
            (edificio) =>
              Array.isArray(inventarioData.edificiosSeleccionadosAscensores) &&
              inventarioData.edificiosSeleccionadosAscensores.includes(edificio.id)
          )
          .map((edificio) => edificio.edificio);

        const nombresEdificiosSeleccionadosTotems = newEdificios
          .filter(
            (edificio) =>
              Array.isArray(inventarioData.edificiosSeleccionadosTotems) &&
              inventarioData.edificiosSeleccionadosTotems.includes(edificio.id)
          )
          .map((edificio) => edificio.edificio);

        const nombresEdificiosSeleccionadosCowork = newEdificios
          .filter(
            (edificio) =>
              Array.isArray(inventarioData.edificiosSeleccionadosCowork) &&
              inventarioData.edificiosSeleccionadosCowork.includes(edificio.id)
          )
          .map((edificio) => edificio.edificio);

        const nombresEdificiosNoSeleccionados = newEdificios
          .filter(
            (edificio) =>
              Array.isArray(inventarioData.edificiosSeleccionadosAscensores) &&
              Array.isArray(inventarioData.edificiosSeleccionadosTotems) &&
              Array.isArray(inventarioData.edificiosSeleccionadosCowork) &&
              !inventarioData.edificiosSeleccionadosAscensores.includes(edificio.id) &&
              !inventarioData.edificiosSeleccionadosTotems.includes(edificio.id) &&
              !inventarioData.edificiosSeleccionadosCowork.includes(edificio.id)
          )
          .map((edificio) => edificio.edificio);

        setNombresEdificiosSeleccionadosAscensores(nombresEdificiosSeleccionadosAscensores);
        setNombresEdificiosSeleccionadosTotems(nombresEdificiosSeleccionadosTotems);
        setNombresEdificiosSeleccionadosCowork(nombresEdificiosSeleccionadosCowork);
        setNombresEdificiosNoSeleccionados(nombresEdificiosNoSeleccionados);
      } catch (error) {
        console.error("Error al obtener edificios:", error);
      }
    };

    initialize();
  }, [pais, inventarioData]);

  const { intervalos, horas, options } = horasSeleccionadas;

  let horasFinal = 0;

  // Verificar si 'intervalos' tiene algún valor definido
  if (intervalos && intervalos.length > 0) {
    // Si hay intervalos, usamos 'horas'
    horasFinal = horas;
  } else if (options && options.length > 0) {
    // Si no hay intervalos pero hay opciones, usamos 'options.hours'
    horasFinal = options[0].hours; // Asumiendo que 'options' es un array de objetos y quieres la propiedad 'hours' del primer objeto
  } else {
    // En caso contrario, establecemos un valor predeterminado o manejo de error según el caso
    horasFinal = 0; // Valor predeterminado, ajusta según tu lógica
  }

  // Cálculos basados en las propiedades desestructuradas
  const salidasAscensores = ascensoresSeleccionados * plazo * frecuenciaNumero * horasFinal;
  const salidasTotems = totemsSeleccionados * plazo * frecuenciaNumero * horasFinal;
  const salidasCowork = coworkSeleccionados * plazo * frecuenciaNumero * horasFinal;

  const valle = 0.15;
  const picoMañana = 0.25;
  const picoMediodia = 0.35;
  const picoTarde = 0.25;

  const contactosTotalAscensores = audienciaSeleccionadaAscensor * 7.48;
  const contactosTotalTotems = audienciaSeleccionadaTotems * 7.48;
  const contactosTotalCowork = audienciaSeleccionadaCowork * 7.48;

  const contactosValleAscensores = contactosTotalAscensores * valle;
  const contactosMañanaAscensores = contactosTotalAscensores * picoMañana;
  const contactosMediodiaAscensores = contactosTotalAscensores * picoMediodia;
  const contactosTardeAscensores = contactosTotalAscensores * picoTarde;

  const contactosVallexHoraAscensores = contactosValleAscensores / 8;
  const contactosMañanaxHoraAscensores = contactosMañanaAscensores / 2;
  const contactosMediodiaxHoraAscensores = contactosMediodiaAscensores / 2;
  const contactosTardexHoraAscensores = contactosTardeAscensores / 2;

  const contactosVallexIntervaloAscensores = contactosVallexHoraAscensores / 2;
  const contactosMañanaxIntervaloAscensores = contactosMañanaxHoraAscensores / 2;
  const contactosMediodiaxIntervaloAscensores = contactosMediodiaxHoraAscensores / 2;
  const contactosTardexIntervaloAscensores = contactosTardexHoraAscensores / 2;

  const contactosPorIntervaloPorPantallaValleAscensores = contactosVallexIntervaloAscensores / ascensoresSeleccionados;
  const contactosPorIntervaloPorPantallaMañanaAscensores =
    contactosMañanaxIntervaloAscensores / ascensoresSeleccionados;
  const contactosPorIntervaloPorPantallaMediodiaAscensores =
    contactosMediodiaxIntervaloAscensores / ascensoresSeleccionados;
  const contactosPorIntervaloPorPantallaTardeAscensores = contactosTardexIntervaloAscensores / ascensoresSeleccionados;

  const contactosValleTotems = contactosTotalTotems * valle;
  const contactosMañanaTotems = contactosTotalTotems * picoMañana;
  const contactosMediodiaTotems = contactosTotalTotems * picoMediodia;
  const contactosTardeTotems = contactosTotalTotems * picoTarde;

  const contactosVallexHoraTotems = contactosValleTotems / 8;
  const contactosMañanaxHoraTotems = contactosMañanaTotems / 2;
  const contactosMediodiaxHoraTotems = contactosMediodiaTotems / 2;
  const contactosTardexHoraTotems = contactosTardeTotems / 2;

  const contactosVallexIntervaloTotems = contactosVallexHoraTotems / 2;
  const contactosMañanaxIntervaloTotems = contactosMañanaxHoraTotems / 2;
  const contactosMediodiaxIntervaloTotems = contactosMediodiaxHoraTotems / 2;
  const contactosTardexIntervaloTotems = contactosTardexHoraTotems / 2;

  const contactosPorIntervaloPorPantallaValleTotems = contactosVallexIntervaloTotems / totemsSeleccionados;
  const contactosPorIntervaloPorPantallaMañanaTotems = contactosMañanaxIntervaloTotems / totemsSeleccionados;
  const contactosPorIntervaloPorPantallaMediodiaTotems = contactosMediodiaxIntervaloTotems / totemsSeleccionados;
  const contactosPorIntervaloPorPantallaTardeTotems = contactosTardexIntervaloTotems / totemsSeleccionados;

  const contactosValleCowork = contactosTotalCowork * valle;
  const contactosMañanaCowork = contactosTotalCowork * picoMañana;
  const contactosMediodiaCowork = contactosTotalCowork * picoMediodia;
  const contactosTardeCowork = contactosTotalCowork * picoTarde;

  const contactosVallexHoraCowork = contactosValleCowork / 8;
  const contactosMañanaxHoraCowork = contactosMañanaCowork / 2;
  const contactosMediodiaxHoraCowork = contactosMediodiaCowork / 2;
  const contactosTardexHoraCowork = contactosTardeCowork / 2;

  const contactosVallexIntervaloCowork = contactosVallexHoraCowork / 2;
  const contactosMañanaxIntervaloCowork = contactosMañanaxHoraCowork / 2;
  const contactosMediodiaxIntervaloCowork = contactosMediodiaxHoraCowork / 2;
  const contactosTardexIntervaloCowork = contactosTardexHoraCowork / 2;

  const contactosPorIntervaloPorPantallaValleCowork = contactosVallexIntervaloCowork / coworkSeleccionados;
  const contactosPorIntervaloPorPantallaMañanaCowork = contactosMañanaxIntervaloCowork / coworkSeleccionados;
  const contactosPorIntervaloPorPantallaMediodiaCowork = contactosMediodiaxIntervaloCowork / coworkSeleccionados;
  const contactosPorIntervaloPorPantallaTardeCowork = contactosTardexIntervaloCowork / coworkSeleccionados;

  // Inicializar contadores para cada período del día
  let countValle = 0;
  let countMañana = 0;
  let countMediodia = 0;
  let countTarde = 0;

  intervalos.forEach((intervalo) => {
    if (
      intervalo === "08:00 - 08:30" ||
      intervalo === "08:30 - 09:00" ||
      intervalo === "09:00 - 09:30" ||
      intervalo === "09:30 - 10:00"
    ) {
      countMañana++;
    } else if (
      intervalo === "12:30 - 13:00" ||
      intervalo === "13:00 - 13:30" ||
      intervalo === "13:30 - 14:00" ||
      intervalo === "14:00 - 14:30"
    ) {
      countMediodia++;
    } else if (
      intervalo === "17:00 - 17:30" ||
      intervalo === "17:30 - 18:00" ||
      intervalo === "18:00 - 18:30" ||
      intervalo === "18:30 - 19:00"
    ) {
      countTarde++;
    } else {
      countValle++;
    }
  });

  // Calcular el total de personas por pantalla para cada período del día
  const totalContactosValleAscensores =
    contactosPorIntervaloPorPantallaValleAscensores * countValle * plazo * ascensoresSeleccionados;
  const totalContactosMañanaAscensores =
    contactosPorIntervaloPorPantallaMañanaAscensores * countMañana * plazo * ascensoresSeleccionados;
  const totalContactosMediodiaAscensores =
    contactosPorIntervaloPorPantallaMediodiaAscensores * countMediodia * plazo * ascensoresSeleccionados;
  const totalContactosTardeAscensores =
    contactosPorIntervaloPorPantallaTardeAscensores * countTarde * plazo * ascensoresSeleccionados;

  let totalContactosCampañaAscensores;

  // Verificar si 'intervalos' tiene algún valor definido
  if (intervalos && intervalos.length > 0) {
    // Si hay intervalos, usamos el cálculo existente para totalContactosCampañaAscensores
    totalContactosCampañaAscensores =
      totalContactosValleAscensores +
      totalContactosMañanaAscensores +
      totalContactosMediodiaAscensores +
      totalContactosTardeAscensores;
  } else if (options && options.length > 0) {
    // Si no hay intervalos pero hay opciones, determinamos el valor según 'options[0].label'
    if (options[0].label.includes("FULL")) {
      totalContactosCampañaAscensores = audienciaSeleccionadaAscensor * 7.48 * plazo;
    } else if (options[0].label.includes("AM") || options[0].label.includes("PM")) {
      totalContactosCampañaAscensores = ((audienciaSeleccionadaAscensor * 7.48) / 2) * plazo;
    } else {
      totalContactosCampañaAscensores = 0; // Valor predeterminado si no coincide con ninguna opción
    }
  } else {
    // En caso contrario, establecemos un valor predeterminado o manejo de error según el caso
    totalContactosCampañaAscensores = 0; // Valor predeterminado, ajusta según tu lógica
  }

  const totalContactosValleTotems =
    contactosPorIntervaloPorPantallaValleTotems * countValle * plazo * totemsSeleccionados;
  const totalContactosMañanaTotems =
    contactosPorIntervaloPorPantallaMañanaTotems * countMañana * plazo * totemsSeleccionados;
  const totalContactosMediodiaTotems =
    contactosPorIntervaloPorPantallaMediodiaTotems * countMediodia * plazo * totemsSeleccionados;
  const totalContactosTardeTotems =
    contactosPorIntervaloPorPantallaTardeTotems * countTarde * plazo * totemsSeleccionados;

  let totalContactosCampañaTotems;

  // Verificar si 'intervalos' tiene algún valor definido
  if (intervalos && intervalos.length > 0) {
    // Si hay intervalos, usamos el cálculo existente para totalContactosCampañaAscensores
    totalContactosCampañaTotems =
      totalContactosValleTotems + totalContactosMañanaTotems + totalContactosMediodiaTotems + totalContactosTardeTotems;
  } else if (options && options.length > 0) {
    if (options[0].label.includes("FULL")) {
      totalContactosCampañaTotems = audienciaSeleccionadaTotems * 7.48 * plazo;
    } else if (options[0].label.includes("AM") || options[0].label.includes("PM")) {
      totalContactosCampañaTotems = ((audienciaSeleccionadaTotems * 7.48) / 2) * plazo;
    } else {
      totalContactosCampañaTotems = 0;
    }
  } else {
    totalContactosCampañaTotems = 0;
  }

  const totalContactosValleCowork =
    contactosPorIntervaloPorPantallaValleCowork * countValle * plazo * coworkSeleccionados;
  const totalContactosMañanaCowork =
    contactosPorIntervaloPorPantallaMañanaCowork * countMañana * plazo * coworkSeleccionados;
  const totalContactosMediodiaCowork =
    contactosPorIntervaloPorPantallaMediodiaCowork * countMediodia * plazo * coworkSeleccionados;
  const totalContactosTardeCowork =
    contactosPorIntervaloPorPantallaTardeCowork * countTarde * plazo * coworkSeleccionados;

  let totalContactosCampañaCowork;

  // Verificar si 'intervalos' tiene algún valor definido
  if (intervalos && intervalos.length > 0) {
    // Si hay intervalos, usamos el cálculo existente para totalContactosCampañaAscensores
    totalContactosCampañaCowork =
      totalContactosValleCowork + totalContactosMañanaCowork + totalContactosMediodiaCowork + totalContactosTardeCowork;
  } else if (options && options.length > 0) {
    if (options[0].label.includes("FULL")) {
      totalContactosCampañaCowork = audienciaSeleccionadaCowork * 7.48 * plazo;
    } else if (options[0].label.includes("AM") || options[0].label.includes("PM")) {
      totalContactosCampañaCowork = ((audienciaSeleccionadaCowork * 7.48) / 2) * plazo;
    } else {
      totalContactosCampañaCowork = 0;
    }
  } else {
    totalContactosCampañaCowork = 0;
  }

  const coeficientesFrecuencia = {
    5: {
      valle: 0.0556,
      picoMañana: 0.1042,
      picoMediodia: 0.125,
      picoTarde: 0.1042,
    },
    10: {
      valle: 0.1111,
      picoMañana: 0.2083,
      picoMediodia: 0.25,
      picoTarde: 0.2083,
    },
    15: {
      valle: 0.1667,
      picoMañana: 0.3125,
      picoMediodia: 0.375,
      picoTarde: 0.3125,
    },
    20: {
      valle: 0.2222,
      picoMañana: 0.4167,
      picoMediodia: 0.5,
      picoTarde: 0.4167,
    },
    25: {
      valle: 0.2778,
      picoMañana: 0.5208,
      picoMediodia: 0.625,
      picoTarde: 0.5208,
    },
    30: {
      valle: 0.3333,
      picoMañana: 0.625,
      picoMediodia: 0.75,
      picoTarde: 0.625,
    },
  };

  // Calcular los impactos totales Ascensores
  let impactosTotalAscensores = 0;

  if (intervalos && intervalos.length > 0) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};

    if (countValle > 0) {
      impactosTotalAscensores += totalContactosValleAscensores * (coeficientes.valle || 0);
    }
    if (countMañana > 0 || countTarde > 0) {
      impactosTotalAscensores +=
        (totalContactosMañanaAscensores + totalContactosTardeAscensores) * (coeficientes.picoMañana || 0);
    }
    if (countMediodia > 0) {
      impactosTotalAscensores += totalContactosMediodiaAscensores * (coeficientes.picoMediodia || 0);
    }
  } else if (options && options.length > 0 && options[0].label.includes("FULL")) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};

    impactosTotalAscensores =
      totalContactosCampañaAscensores * valle * (coeficientes.valle || 0) +
      totalContactosCampañaAscensores * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaAscensores * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaAscensores * picoTarde * (coeficientes.picoTarde || 0);
  } else if (options && options.length > 0 && options[0].label.includes("AM")) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};

    impactosTotalAscensores =
      totalContactosCampañaAscensores * valle * (coeficientes.valle || 0) +
      totalContactosCampañaAscensores * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaAscensores * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaAscensores * picoTarde * (coeficientes.picoTarde || 0);
  } else if (options && options.length > 0 && options[0].label.includes("PM")) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};

    impactosTotalAscensores =
      totalContactosCampañaAscensores * valle * (coeficientes.valle || 0) +
      totalContactosCampañaAscensores * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaAscensores * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaAscensores * picoTarde * (coeficientes.picoTarde || 0);
  } else {
    impactosTotalAscensores = 0;
  }

  impactosTotalAscensores = Math.round(impactosTotalAscensores * 100) / 100;

  let impactosTotalTotems = 0;

  if (intervalos && intervalos.length > 0) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};

    if (countValle > 0) {
      impactosTotalTotems += totalContactosValleTotems * (coeficientes.valle || 0);
    }
    if (countMañana > 0 || countTarde > 0) {
      impactosTotalTotems += (totalContactosMañanaTotems + totalContactosTardeTotems) * (coeficientes.picoMañana || 0);
    }
    if (countMediodia > 0) {
      impactosTotalTotems += totalContactosMediodiaTotems * (coeficientes.picoMediodia || 0);
    }
  } else if (options && options.length > 0 && options[0].label.includes("FULL")) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};

    impactosTotalTotems =
      totalContactosCampañaTotems * valle * (coeficientes.valle || 0) +
      totalContactosCampañaTotems * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaTotems * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaTotems * picoTarde * (coeficientes.picoTarde || 0);
  } else if (options && options.length > 0 && options[0].label.includes("AM")) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};

    impactosTotalTotems =
      totalContactosCampañaTotems * valle * (coeficientes.valle || 0) +
      totalContactosCampañaTotems * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaTotems * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaTotems * picoTarde * (coeficientes.picoTarde || 0);
  } else if (options && options.length > 0 && options[0].label.includes("PM")) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};

    impactosTotalTotems =
      totalContactosCampañaTotems * valle * (coeficientes.valle || 0) +
      totalContactosCampañaTotems * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaTotems * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaTotems * picoTarde * (coeficientes.picoTarde || 0);
  } else {
    impactosTotalTotems = 0;
  }

  impactosTotalTotems = Math.round(impactosTotalTotems * 100) / 100;

  let impactosTotalCowork = 0;

  if (intervalos && intervalos.length > 0) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};

    if (countValle > 0) {
      impactosTotalCowork += totalContactosValleCowork * (coeficientes.valle || 0);
    }
    if (countMañana > 0 || countTarde > 0) {
      impactosTotalCowork += (totalContactosMañanaCowork + totalContactosTardeCowork) * (coeficientes.picoMañana || 0);
    }
    if (countMediodia > 0) {
      impactosTotalCowork += totalContactosMediodiaCowork * (coeficientes.picoMediodia || 0);
    }
  } else if (options && options.length > 0 && options[0].label.includes("FULL")) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};

    impactosTotalCowork =
      totalContactosCampañaCowork * valle * (coeficientes.valle || 0) +
      totalContactosCampañaCowork * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaCowork * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaCowork * picoTarde * (coeficientes.picoTarde || 0);
  } else if (options && options.length > 0 && options[0].label.includes("AM")) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};

    impactosTotalCowork =
      totalContactosCampañaCowork * valle * (coeficientes.valle || 0) +
      totalContactosCampañaCowork * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaCowork * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaCowork * picoTarde * (coeficientes.picoTarde || 0);
  } else if (options && options.length > 0 && options[0].label.includes("PM")) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};

    impactosTotalCowork =
      totalContactosCampañaCowork * valle * (coeficientes.valle || 0) +
      totalContactosCampañaCowork * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaCowork * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaCowork * picoTarde * (coeficientes.picoTarde || 0);
  } else {
    impactosTotalCowork = 0;
  }

  impactosTotalCowork = Math.round(impactosTotalCowork * 100) / 100;

  let duracionIncidenciaCotizacion;

  if (duracion === 6) {
    duracionIncidenciaCotizacion = 1;
  } else if (duracion === 7) {
    duracionIncidenciaCotizacion = 1.0125;
  } else if (duracion === 8) {
    duracionIncidenciaCotizacion = 1.025;
  } else if (duracion === 9) {
    duracionIncidenciaCotizacion = 1.0375;
  } else if (duracion === 10) {
    duracionIncidenciaCotizacion = 1.05;
  } else if (duracion === 11) {
    duracionIncidenciaCotizacion = 1.0625;
  } else if (duracion === 12) {
    duracionIncidenciaCotizacion = 1.075;
  } else if (duracion === 13) {
    duracionIncidenciaCotizacion = 1.0875;
  } else if (duracion === 14) {
    duracionIncidenciaCotizacion = 1.1;
  } else if (duracion === 15) {
    duracionIncidenciaCotizacion = 1.1125;
  } else {
    duracionIncidenciaCotizacion = 0;
  }

  const cpmReferencia = 6.9 * duracionIncidenciaCotizacion;

  const cpmAscensores =
    (intervalos.length > 0
      ? cpmReferencia * 2.5
      : options && options.length > 0 && options[0].label.includes("FULL")
        ? cpmReferencia * obtenerTipoDeCambio(pais)
        : cpmReferencia * 1.5 * obtenerTipoDeCambio(pais)) / 1000;

  const cpmTotems = cpmAscensores * 0.9;
  const cpmCowork = cpmAscensores * 0.9;

  const inversionAscensores = impactosTotalAscensores * cpmAscensores;
  const inversionTotems = impactosTotalTotems * cpmTotems;
  const inversionCowork = impactosTotalCowork * cpmCowork;

  const salidasTotales = salidasAscensores + salidasTotems + salidasCowork;

  const impactosTotales =
    (impactosTotalAscensores ? impactosTotalAscensores : 0) +
    (impactosTotalTotems ? impactosTotalTotems : 0) +
    (impactosTotalCowork ? impactosTotalCowork : 0);

  if (intervalos && intervalos.length > 0) {
    selectedMoneda = "US$";
  } else if (options && options.length > 0 && options[0].label.includes("FULL")) {
    selectedMoneda = moneda(pais);
  } else {
    selectedMoneda = moneda(pais);
  }

  const fragmentos = horasFinal * 2; // Cada fragmento representa 0.5 horas, por lo que multiplicamos por 2
  const porcentajeDescuentoPorFragmento = 0.0035714285714285714; // 0.35% en decimal

  const descuentoHorasOn = fragmentos * porcentajeDescuentoPorFragmento;

  let descuentoFrecuencia;

  if (frecuenciaNumero === 5) {
    descuentoFrecuencia = 0.05;
  } else if (frecuenciaNumero === 10) {
    descuentoFrecuencia = 0.01;
  } else if (frecuenciaNumero === 15) {
    descuentoFrecuencia = 0.015;
  } else if (frecuenciaNumero === 20) {
    descuentoFrecuencia = 0.02;
  } else if (frecuenciaNumero === 25) {
    descuentoFrecuencia = 0.025;
  } else if (frecuenciaNumero === 30) {
    descuentoFrecuencia = 0.03;
  } else {
    descuentoFrecuencia = 0;
  }

  let descuentoDuracion;

  if (duracion === 6) {
    descuentoDuracion = 0.01;
  } else if (duracion === 7) {
    descuentoDuracion = 0.0125;
  } else if (duracion === 8) {
    descuentoDuracion = 0.015;
  } else if (duracion === 9) {
    descuentoDuracion = 0.0175;
  } else if (duracion === 10) {
    descuentoDuracion = 0.02;
  } else if (duracion === 11) {
    descuentoDuracion = 0.0225;
  } else if (duracion === 12) {
    descuentoDuracion = 0.025;
  } else if (duracion === 13) {
    descuentoDuracion = 0.0275;
  } else if (duracion === 14) {
    descuentoDuracion = 0.03;
  } else if (duracion === 15) {
    descuentoDuracion = 0.0325;
  } else {
    descuentoDuracion = 0;
  }

  const descuentoInventarioAscensores = (ascensoresSeleccionados / ascensoresTotal) * 0.03;
  const descuentoInventarioTotems = (totemsSeleccionados / totemsTotal) * 0.03;
  const descuentoInventarioCowork = (coworkSeleccionados / coworkTotal) * 0.03;
  const descuentoPlazo = (plazo / 260) * 0.03;

  const descuentoTotalAscensores =
    intervalos.length > 0
      ? 0
      : descuentoInventarioAscensores && descuentoPlazo && descuentoDuracion && descuentoFrecuencia && descuentoHorasOn
        ? descuentoInventarioAscensores + descuentoPlazo + descuentoDuracion + descuentoFrecuencia + descuentoHorasOn
        : null;

  const descuentoTotalTotems =
    intervalos.length > 0
      ? 0
      : descuentoInventarioTotems && descuentoPlazo && descuentoDuracion && descuentoFrecuencia && descuentoHorasOn
        ? descuentoInventarioTotems + descuentoPlazo + descuentoDuracion + descuentoFrecuencia + descuentoHorasOn
        : null;

  const descuentoTotalCowork =
    intervalos.length > 0
      ? 0
      : descuentoInventarioCowork && descuentoPlazo && descuentoDuracion && descuentoFrecuencia && descuentoHorasOn
        ? descuentoInventarioCowork + descuentoPlazo + descuentoDuracion + descuentoFrecuencia + descuentoHorasOn
        : null;

  const inversionAscensoresFinal =
    intervalos.length > 0 ? inversionAscensores : inversionAscensores * (1 - descuentoTotalAscensores);
  const inversionTotemsFinal = intervalos.length > 0 ? inversionTotems : inversionTotems * (1 - descuentoTotalTotems);
  const inversionCoworkFinal = intervalos.length > 0 ? inversionCowork : inversionCowork * (1 - descuentoTotalCowork);

  const tarifaFriaAscensores = (100 * inversionAscensores) / (100 - descuentoTotalAscensores);
  const tarifaFriaTotems = (100 * inversionTotems) / (100 - descuentoTotalTotems);
  const tarifaFriaCowork = (100 * inversionCowork) / (100 - descuentoTotalCowork);
  const tarifaFriaTotal =
    (tarifaFriaAscensores ? tarifaFriaAscensores : 0) +
    (tarifaFriaTotems ? tarifaFriaTotems : 0) +
    (tarifaFriaCowork ? tarifaFriaCowork : 0);

  const inversionFinalTotal =
    (inversionAscensoresFinal ? inversionAscensoresFinal : 0) +
    (inversionTotemsFinal ? inversionTotemsFinal : 0) +
    (inversionCoworkFinal ? inversionCoworkFinal : 0);

  const totalInversion = inversionAscensoresFinal + inversionTotemsFinal + inversionCoworkFinal;
  const descuentoTotalFinal =
    totalInversion > 0
      ? (descuentoTotalAscensores * inversionAscensoresFinal +
          descuentoTotalTotems * inversionTotemsFinal +
          descuentoTotalCowork * inversionCoworkFinal) /
        totalInversion
      : 0;

  const cpmAscensoresFinal = (inversionAscensoresFinal / impactosTotalAscensores) * 1000;
  const cpmTotemsFinal = (inversionTotemsFinal / impactosTotalTotems) * 1000;
  const cpmCoworkFinal = (inversionCoworkFinal / impactosTotalCowork) * 1000;
  const cpmTotalFinal =
    (((inversionAscensoresFinal ? inversionAscensoresFinal : 0) +
      (inversionTotemsFinal ? inversionTotemsFinal : 0) +
      (inversionCoworkFinal ? inversionCoworkFinal : 0)) /
      ((impactosTotalAscensores ? impactosTotalAscensores : 0) +
        (impactosTotalTotems ? impactosTotalTotems : 0) +
        (impactosTotalCowork ? impactosTotalCowork : 0))) *
    1000;

  const todosLosTotalesCompletos = () =>
    edificiosUnicosTotal > 0 &&
    ascensoresSeleccionados + totemsSeleccionados + coworkSeleccionados > 0 &&
    audienciaSeleccionadaTotal > 0 &&
    horasFinal > 0 &&
    plazo > 0 &&
    salidasTotales > 0 &&
    impactosTotales > 0 &&
    duracion > 0 &&
    tarifaFriaTotal > 0 &&
    inversionFinalTotal > 0 &&
    cpmTotalFinal > 0;

  if (session === undefined) return <Loader fullPage />;

  const usuarioNombre = nombre || "usuario";
  const usuarioApellido = apellido || "usuario";

  const codigoGenerado = generarCodigoAleatorio();

  const handleSaveCotizacion = async () => {
    const cotizacionData = {
      ascensoresSeleccionados,
      audienciaSeleccionadaAscensor,
      audienciaSeleccionadaCowork,
      audienciaSeleccionadaTotal,
      audienciaSeleccionadaTotems,
      codigoGenerado,
      coworkSeleccionados,
      cpmAscensoresFinal,
      cpmTotalFinal,
      cpmTotemsFinal,
      descuentoTotalAscensores,
      descuentoTotalCowork,
      descuentoTotalFinal,
      descuentoTotalTotems,
      duracion,
      edificiosSeleccionadosAscensores,
      edificiosSeleccionadosCowork,
      edificiosSeleccionadosTotems,
      edificiosUnicosTotal,
      horas,
      horasFinal,
      impactosTotalAscensores,
      impactosTotalCowork,
      impactosTotalTotems,
      impactosTotales,
      intervalos,
      inversionAscensoresFinal,
      inversionCoworkFinal,
      inversionFinalTotal,
      inversionTotemsFinal,
      isProgrammaticSelected,
      nombresEdificiosNoSeleccionados,
      nombresEdificiosSeleccionadosAscensores,
      nombresEdificiosSeleccionadosCowork,
      nombresEdificiosSeleccionadosTotems,
      options,
      pais,
      plazo,
      salidasAscensores,
      salidasCowork,
      salidasTotales,
      salidasTotems,
      tarifaFriaAscensores,
      tarifaFriaCowork,
      tarifaFriaTotal,
      tarifaFriaTotems,
      totemsSeleccionados,
      usuarioApellido: apellido || "usuario",
      usuarioNombre: nombre || "usuario",
    };

    // Llama a la función para guardar la cotización en Supabase
    await saveCotizacion(cotizacionData);
  };

  return (
    <div>
      <div className="table-container">
        <table className="table table-bordered table-striped table-hover">
          <thead>
            <tr className="table-dark">
              <th width="8%" scope="col">
                CIRCUITO
              </th>
              <th width="6%" scope="col">
                EDIFICIOS
              </th>
              <th width="6%" scope="col">
                PANTALLAS
              </th>
              <th width="6%" scope="col">
                AUDIENCIA
              </th>
              <th width="6%" scope="col">
                HORAS ON
              </th>
              <th width="6%" scope="col">
                PLAZO (días)
              </th>
              <th width="6%" scope="col">
                IMPRESIONES
              </th>
              <th width="6%" scope="col">
                IMPACTOS
              </th>
              <th width="6%" scope="col">
                DURACIÓN (segundos)
              </th>
              <th width="12%" scope="col">
                COTIZACIÓN
              </th>
              {!isProgrammaticSelected && (
                <th width="6%" scope="col" className="bg-danger">
                  DESCUENTO
                </th>
              )}
              <th width="12%" scope="col" className="bg-success">
                INVERSIÓN
              </th>
              <th width="8%" scope="col" className="bg-primary">
                CPM
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" className="text-start">
                ASCENSORES
              </th>
              <td>{ascensoresSeleccionados ? edificiosSeleccionadosAscensores : "-"}</td>
              <td>{ascensoresSeleccionados ? ascensoresSeleccionados : "-"}</td>
              <td>
                {ascensoresSeleccionados
                  ? audienciaSeleccionadaAscensor.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </td>
              <td>
                {ascensoresSeleccionados && horasFinal
                  ? horasFinal.toLocaleString(localString(pais), { minimumFractionDigits: 1, maximumFractionDigits: 1 })
                  : "-"}
              </td>
              <td>{ascensoresSeleccionados && plazo ? plazo : "-"}</td>
              <td>
                {salidasAscensores
                  ? salidasAscensores.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </td>
              <td>
                {impactosTotalAscensores
                  ? impactosTotalAscensores.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </td>
              <td>{ascensoresSeleccionados && duracion ? duracion : "-"}</td>
              <td className="text-center">
                {tarifaFriaAscensores
                  ? `${selectedMoneda} ${tarifaFriaAscensores.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`
                  : "-"}
              </td>
              {!isProgrammaticSelected && (
                <td className="text-center">
                  {descuentoTotalAscensores
                    ? `${(descuentoTotalAscensores * 100).toLocaleString(localString(pais), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      })}%`
                    : "-"}
                </td>
              )}{" "}
              <td className="text-center">
                {inversionAscensoresFinal
                  ? `${selectedMoneda} ${inversionAscensoresFinal.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`
                  : "-"}
              </td>
              <td className="text-center">
                {cpmAscensoresFinal
                  ? `${selectedMoneda} ${cpmAscensoresFinal.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`
                  : "-"}
              </td>
            </tr>
            {totemsTotal > 0 && (
              <tr>
                <th scope="row" className="text-start">
                  CIRCULACIÓN
                </th>
                <td>{totemsSeleccionados ? edificiosSeleccionadosTotems : "-"}</td>
                <td>{totemsSeleccionados ? totemsSeleccionados : "-"}</td>
                <td>
                  {totemsSeleccionados
                    ? audienciaSeleccionadaTotems.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"}
                </td>
                <td>
                  {totemsSeleccionados && horasFinal
                    ? horasFinal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"}
                </td>
                <td>{totemsSeleccionados && plazo ? plazo : "-"}</td>
                <td>
                  {salidasTotems
                    ? salidasTotems.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"}
                </td>
                <td>
                  {impactosTotalTotems
                    ? impactosTotalTotems.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"}
                </td>
                <td>{totemsSeleccionados && duracion ? duracion : "-"}</td>
                <td className="text-center">
                  {tarifaFriaTotems
                    ? `${selectedMoneda} ${tarifaFriaTotems.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`
                    : "-"}
                </td>
                {!isProgrammaticSelected && (
                  <td className="text-center">
                    {descuentoTotalTotems && totemsSeleccionados
                      ? `${(descuentoTotalTotems * 100).toLocaleString(localString(pais), {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        })}%`
                      : "-"}
                  </td>
                )}
                <td className="text-center">
                  {inversionTotemsFinal
                    ? `${selectedMoneda} ${inversionTotemsFinal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`
                    : "-"}
                </td>
                <td className="text-center">
                  {cpmTotemsFinal
                    ? `${selectedMoneda} ${cpmTotemsFinal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`
                    : "-"}
                </td>
              </tr>
            )}
            {coworkTotal > 0 && (
              <tr>
                <th scope="row" className="text-start">
                  COWORK iF
                </th>
                <td>{coworkSeleccionados ? edificiosSeleccionadosCowork : "-"}</td>
                <td>{coworkSeleccionados ? coworkSeleccionados : "-"}</td>
                <td>
                  {coworkSeleccionados
                    ? audienciaSeleccionadaCowork.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"}
                </td>
                <td>
                  {coworkSeleccionados && horasFinal
                    ? horasFinal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"}
                </td>
                <td>{coworkSeleccionados && plazo ? plazo : "-"}</td>
                <td>
                  {salidasCowork
                    ? salidasCowork.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"}
                </td>
                <td>
                  {impactosTotalCowork
                    ? impactosTotalCowork.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"}
                </td>
                <td>{coworkSeleccionados && duracion ? duracion : "-"}</td>
                <td className="text-center">
                  {tarifaFriaCowork
                    ? `${selectedMoneda} ${tarifaFriaCowork.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`
                    : "-"}
                </td>
                {!isProgrammaticSelected && (
                  <td className="text-center">
                    {descuentoTotalCowork && coworkSeleccionados
                      ? `${(descuentoTotalCowork * 100).toLocaleString(localString(pais), {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        })}%`
                      : "-"}
                  </td>
                )}
                <td className="text-center">
                  {inversionCoworkFinal
                    ? `${selectedMoneda} ${inversionCoworkFinal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`
                    : "-"}
                </td>
                <td className="text-center">
                  {cpmCoworkFinal
                    ? `${selectedMoneda} ${cpmCoworkFinal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`
                    : "-"}
                </td>
              </tr>
            )}
            {totemsTotal > 0 && (
              <tr className="table-dark fw-bold">
                <th scope="row" className="text-start">
                  TOTALES
                </th>
                <td>{ascensoresSeleccionados || totemsSeleccionados ? edificiosUnicosTotal : "-"}</td>
                <td>
                  {ascensoresSeleccionados || totemsSeleccionados
                    ? ascensoresSeleccionados + totemsSeleccionados + coworkSeleccionados
                    : "-"}
                </td>
                <td>
                  {ascensoresSeleccionados || totemsSeleccionados
                    ? audienciaSeleccionadaTotal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"}
                </td>
                <td>
                  {(ascensoresSeleccionados || totemsSeleccionados) && horasFinal
                    ? horasFinal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"}
                </td>
                <td>{(ascensoresSeleccionados || totemsSeleccionados) && plazo ? plazo : "-"}</td>
                <td>
                  {(ascensoresSeleccionados || totemsSeleccionados) && salidasTotales
                    ? salidasTotales.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"}
                </td>
                <td>
                  {impactosTotalAscensores || impactosTotalTotems
                    ? impactosTotales.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"}
                </td>
                <td>{(ascensoresSeleccionados || totemsSeleccionados) && duracion ? duracion : "-"}</td>
                <td className="text-center">
                  {tarifaFriaTotal
                    ? `${selectedMoneda} ${tarifaFriaTotal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`
                    : "-"}
                </td>
                {!isProgrammaticSelected && (
                  <td className="text-center">
                    {descuentoTotalAscensores || descuentoTotalTotems
                      ? `${(descuentoTotalFinal * 100).toLocaleString(localString(pais), {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        })}%`
                      : "-"}
                  </td>
                )}
                <td className="text-center">
                  {inversionFinalTotal
                    ? `${selectedMoneda} ${inversionFinalTotal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`
                    : "-"}
                </td>
                <td>
                  {cpmTotalFinal
                    ? `${selectedMoneda} ${cpmTotalFinal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`
                    : "-"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Botón para generar el PDF */}
      <PDFDownloadLink
        className={`btn mt-2 btn-primary text-white ${todosLosTotalesCompletos() ? "" : "disabled"}`} // Agregamos clases Bootstrap y lógica de habilitación
        document={
          <PdfCotizacion
            ascensoresSeleccionados={ascensoresSeleccionados}
            audienciaSeleccionadaAscensor={audienciaSeleccionadaAscensor}
            audienciaSeleccionadaCowork={audienciaSeleccionadaCowork}
            audienciaSeleccionadaTotal={audienciaSeleccionadaTotal}
            audienciaSeleccionadaTotems={audienciaSeleccionadaTotems}
            codigoGenerado={codigoGenerado}
            coworkSeleccionados={coworkSeleccionados}
            cpmAscensoresFinal={cpmAscensoresFinal}
            cpmCoworkFinal={cpmCoworkFinal}
            cpmTotalFinal={cpmTotalFinal}
            cpmTotemsFinal={cpmTotemsFinal}
            descuentoTotalAscensores={descuentoTotalAscensores}
            descuentoTotalCowork={descuentoTotalCowork}
            descuentoTotalFinal={descuentoTotalFinal}
            descuentoTotalTotems={descuentoTotalTotems}
            duracion={duracion}
            edificiosSeleccionadosAscensores={edificiosSeleccionadosAscensores}
            edificiosSeleccionadosCowork={edificiosSeleccionadosCowork}
            edificiosSeleccionadosTotems={edificiosSeleccionadosTotems}
            edificiosUnicosTotal={edificiosUnicosTotal}
            horas={horas}
            horasFinal={horasFinal}
            impactosTotalAscensores={impactosTotalAscensores}
            impactosTotalCowork={impactosTotalCowork}
            impactosTotalTotems={impactosTotalTotems}
            impactosTotales={impactosTotales}
            intervalos={intervalos}
            inversionAscensoresFinal={inversionAscensoresFinal}
            inversionCoworkFinal={inversionCoworkFinal}
            inversionFinalTotal={inversionFinalTotal}
            inversionTotemsFinal={inversionTotemsFinal}
            isProgrammaticSelected={isProgrammaticSelected}
            nombresEdificiosNoSeleccionados={nombresEdificiosNoSeleccionados}
            nombresEdificiosSeleccionadosAscensores={nombresEdificiosSeleccionadosAscensores}
            nombresEdificiosSeleccionadosCowork={nombresEdificiosSeleccionadosCowork}
            nombresEdificiosSeleccionadosTotems={nombresEdificiosSeleccionadosTotems}
            options={options}
            pais={pais}
            plazo={plazo}
            salidasAscensores={salidasAscensores}
            salidasCowork={salidasCowork}
            salidasTotales={salidasTotales}
            salidasTotems={salidasTotems}
            tarifaFriaAscensores={tarifaFriaAscensores}
            tarifaFriaCowork={tarifaFriaCowork}
            tarifaFriaTotal={tarifaFriaTotal}
            tarifaFriaTotems={tarifaFriaTotems}
            totemsSeleccionados={totemsSeleccionados}
            usuarioApellido={usuarioApellido}
            usuarioNombre={usuarioNombre}
          />
        }
        fileName={`${obtenerFechaActual()}. ${operacionTextual(pais)} - Cotización ${codigoGenerado}.pdf`}
        disabled={!todosLosTotalesCompletos()} // Deshabilita el botón si no están completos los totales
        onClick={handleSaveCotizacion}
      >
        Generar Cotización
      </PDFDownloadLink>
    </div>
  );
};
